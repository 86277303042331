@import url(https://fonts.googleapis.com/css2?family=Inter&display=swap);
/*
 * Copyright (C) 2022 Soham Pardeshi.  All rights reserved.  Permission is
 * hereby granted to students registered for University of Washington
 * CSE 331 for use solely during Summer Quarter 2021 for purposes of
 * the course.  No other use, copying, distribution, or modification
 * is permitted without prior written consent. Copyrights for
 * third-party components of this work must be honored.  Instructors
 * interested in reusing these course materials should contact the
 * author.
 */

/*
 * CSS file used by the Application
 *
 * It provides placement and style information for the components in the app
 * and only exists for aesthetic purposes. The app would work just fine if this
 * file was empty.
 */

:root {
    --map-tiles-filter: none;
    --background: rgba(255, 255, 255, 0.7);
    --dropdown-background: rgba(255, 255, 255, 1);
    --dropdown-border: rgba(204, 204, 204, 1);
    --option-highlight: rgb(178, 212, 255);
    --button-background: rgba(200, 200, 200, 0.6);
    --button-background-hover: rgba(235, 235, 235, 1);
    --button-background-active: rgba(210, 210, 210, 1);
    --text-color: black;
    --body-background: white;
    --path-color: #2684FF;
    --path-outline: rgba(107, 107, 107, 0.38);
}

::-webkit-scrollbar {
    width: 4px;
    height: 0;
}


::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.7);
    background: var(--background);
    border-radius: 4px;
}


::-webkit-scrollbar-thumb {
    background: rgba(200, 200, 200, 0.6);
    background: var(--button-background);
    border-radius: 4px;
}


::-webkit-scrollbar-thumb:hover {
    background: rgba(235, 235, 235, 1);
    background: var(--button-background-hover);
}

.leaflet-control, .leaflet-control a {
    -webkit-backdrop-filter: blur(20px);
            backdrop-filter: blur(20px);
    background-color: rgba(255, 255, 255, 0.7) !important;
    background-color: var(--background) !important;
}

[data-theme="dark"] {
    --map-tiles-filter: invert(100%) hue-rotate(190deg) brightness(110%) contrast(85%) saturate(0.7);
    --background: rgba(22, 22, 22, 0.6);
    --dropdown-background: rgba(22, 22, 22, 1);
    --dropdown-border: rgba(50, 50, 50, 1);
    --option-highlight: rgb(71, 86, 106);
    --button-background: rgba(65, 65, 65, 0.7);
    --button-background-hover: rgba(100, 100, 100, 1);
    --button-background-active: rgba(70, 70, 70, 1);
    --text-color: rgba(228, 228, 228, 1);
    --body-background: black;
    --path-outline: rgba(80, 80, 80, 0.6);
}

.leaflet-container {
    background: white !important;
    background: var(--body-background) !important;
}

.leaflet-tile-pane {
    -webkit-filter: none;
            filter: none;
    -webkit-filter: var(--map-tiles-filter, none);
            filter: var(--map-tiles-filter, none);
    transition: -webkit-filter 0.1s;
    transition: filter 0.1s;
    transition: filter 0.1s, -webkit-filter 0.1s;
    background-color: rgba(255, 255, 255, 0.7) !important;
    background-color: var(--background) !important;
}

.leaflet-control {
    color: black !important;
    color: var(--text-color) !important;
    background-color: rgba(255, 255, 255, 0.7) !important;
    background-color: var(--background) !important;
    border-bottom-color: gray;
}

.leaflet-control a:hover {
    background-color: rgba(255, 255, 255, 0.7) !important;
    background-color: var(--background) !important;
    color: #979797;
}

path.stroke-polyline {
    -webkit-filter: drop-shadow(2px 2px 1px rgba(107, 107, 107, 0.38)) drop-shadow(-2px -2px 1px rgba(107, 107, 107, 0.38)) drop-shadow(2px -2px 1px rgba(107, 107, 107, 0.38)) drop-shadow(-2px 2px 1px rgba(107, 107, 107, 0.38));
            filter: drop-shadow(2px 2px 1px rgba(107, 107, 107, 0.38)) drop-shadow(-2px -2px 1px rgba(107, 107, 107, 0.38)) drop-shadow(2px -2px 1px rgba(107, 107, 107, 0.38)) drop-shadow(-2px 2px 1px rgba(107, 107, 107, 0.38));
    -webkit-filter: drop-shadow(2px 2px 1px var(--path-outline)) drop-shadow(-2px -2px 1px var(--path-outline)) drop-shadow(2px -2px 1px var(--path-outline)) drop-shadow(-2px 2px 1px var(--path-outline));
            filter: drop-shadow(2px 2px 1px var(--path-outline)) drop-shadow(-2px -2px 1px var(--path-outline)) drop-shadow(2px -2px 1px var(--path-outline)) drop-shadow(-2px 2px 1px var(--path-outline));
    stroke-dasharray: 200%;
    stroke-dashoffset: 200%;
    -webkit-animation: dash 4s ease-out forwards;
            animation: dash 4s ease-out forwards;
}

@-webkit-keyframes dash {
    to {
        stroke-dashoffset: 0;
    }
}

@keyframes dash {
    to {
        stroke-dashoffset: 0;
    }
}

.starting-point-marker {
    -webkit-filter: drop-shadow(2px 2px 1px rgba(107, 107, 107, 0.38)) drop-shadow(-2px -2px 1px rgba(107, 107, 107, 0.38)) drop-shadow(2px -2px 1px rgba(107, 107, 107, 0.38)) drop-shadow(-2px 2px 1px rgba(107, 107, 107, 0.38));
            filter: drop-shadow(2px 2px 1px rgba(107, 107, 107, 0.38)) drop-shadow(-2px -2px 1px rgba(107, 107, 107, 0.38)) drop-shadow(2px -2px 1px rgba(107, 107, 107, 0.38)) drop-shadow(-2px 2px 1px rgba(107, 107, 107, 0.38));
    -webkit-filter: drop-shadow(2px 2px 1px var(--path-outline)) drop-shadow(-2px -2px 1px var(--path-outline)) drop-shadow(2px -2px 1px var(--path-outline)) drop-shadow(-2px 2px 1px var(--path-outline));
            filter: drop-shadow(2px 2px 1px var(--path-outline)) drop-shadow(-2px -2px 1px var(--path-outline)) drop-shadow(2px -2px 1px var(--path-outline)) drop-shadow(-2px 2px 1px var(--path-outline));
}

/*@media (prefers-color-scheme: dark) {*/
/*    .leaflet-container {*/
/*        background: var(--body-background);*/
/*    }*/
/*    .leaflet-tile-pane {*/
/*        filter:var(--map-tiles-filter, none);*/
/*    }*/
/*    .leaflet-control, .leaflet-control a {*/
/*        !*background-color: var(--background) !important;*!*/
/*        color: var(--text-color);*/
/*        border-bottom-color: gray;*/
/*    }*/
/*    .leaflet-control a:hover {*/
/*        !*background-color: var(--background) !important;*!*/
/*        color: #979797;*/
/*    }*/
/*    :root {*/
/*        --background: rgba(22, 22, 22, 0.6);*/
/*        --dropdown-background: rgba(22, 22, 22, 1);*/
/*        --dropdown-border: rgba(50, 50, 50, 1);*/
/*        --option-highlight: rgb(71, 86, 106);*/
/*        --button-background: rgba(65, 65, 65, 0.7);*/
/*        --button-background-hover: rgba(100, 100, 100, 1);*/
/*        --button-background-active: rgba(70, 70, 70, 1);*/
/*        --text-color: rgba(228, 228, 228, 1);;*/
/*    }*/
/*    body {*/
/*        background-color: var(--body-background);*/
/*        color: var(--text-color);*/
/*    }*/
/*    button {*/
/*        color: white;*/
/*    }*/
/*    textarea {*/
/*        color: white;*/
/*    }*/
/*}*/

body {
    font-family: 'Inter', sans-serif;
    margin: 0;
    background-color: white;
    background-color: var(--body-background);
    color: black;
    color: var(--text-color);
    transition: background-color 0.2s;
}

button {
    color: black;
    color: var(--text-color);
}

textarea {
    color: black;
    color: var(--text-color);
}


/* Sets size of map, which has class name "leaflet-container" */
.leaflet-container {
    width: 100vw;
    height: 100vh;
}

.map-controls {
    position: absolute;
    display: flex;
    flex-direction: column;
    right: 0;
    top: 0;
    /*width: 300px;*/
    margin: 35px;
    z-index: 1000;
    text-align: left;
    background: rgba(255, 255, 255, 0.7);
    background: var(--background);
    -webkit-backdrop-filter: blur(20px);
            backdrop-filter: blur(20px);
    border-radius: 12px;
    padding: 8px 4px;
}

.map-controls button {
    color: black;
    color: var(--text-color);
    text-align: center;
    font-weight: bold;
    font-size: 22px;
    padding: 4px 10px;
    border-radius: 6px;
}

.destination-input, .route-information-container {
    position: absolute;
    width: 400px;
    margin: 35px;
    text-align: left;
    background: rgba(255, 255, 255, 0.7);
    background: var(--background);
    -webkit-backdrop-filter: blur(20px);
            backdrop-filter: blur(20px);
    border-radius: 16px;
    padding: 12px;
}

.destination-input {
    left: 0;
    top: 0;
    z-index: 1000;
}

.destination-input .dropdown-outer-container {
    display: flex;
}

.destination-input .dropdown-container {
    width: 100%;
    /*height: 100px;*/
}

.destination-input .button-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

.destination-input .button-container button {
    margin-left: 8px;
    margin-right: 0;
    padding: 10px;
}

.destination-input .button-container button#top {
    margin-top: 0;
}

.destination-input .button-container button#bottom {
    margin-bottom: 0;
}

.destination-input .button-container svg {
    margin: 0;
    display: block;
    font-size: 14px;
    text-align: center;
}

.route-information-container {
    -webkit-animation: fadein 0.3s;
            animation: fadein 0.3s;
    top: 120px;
    z-index: 900;
    max-height: 75vh;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.route-information {
    display: flex;
    justify-content: space-between;
}

@-webkit-keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.route-information svg {
    top: .1em;
    position: relative;
    display: inline-flex;
    align-self: center;
}

.route-information h2 {
    margin: 0;
    align-self: center;
}

.route-information p {
    align-self: center;
}

.turn-by-turn {
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.turn-by-turn .directions {
    overflow-y: auto;
}

.turn-by-turn .directions .direction-entry {
    display: flex;
    flex-shrink: 0;
    align-items: center;
}

.turn-by-turn svg {
    top: -.1em;
    position: relative;
    display: inline-flex;
    align-self: center;
    height: 2em;
    min-width: 2em;
    padding-right: 8px;
}

.turn-by-turn h3 {
    align-self: center;
    font-weight: normal;
    font-size: 1em;
    margin: 2px;
}

.turn-by-turn h4 {
    margin: 10px 4px 10px 4px;
}

.turn-by-turn h2,h1 {
    margin: 5px 4px 5px 4px;
}

.turn-by-turn h2 {
    font-size: 1.3em;
    background: linear-gradient(to right, #6666ff, #0099ff , #00ff00, #ff3399, #6666ff);
    -webkit-background-clip: text;
    /*background-clip: text;*/
    color: transparent;
    -webkit-animation: rainbow_animation 4s linear infinite;
            animation: rainbow_animation 4s linear infinite;
    background-size: 400% 100%;
}

@-webkit-keyframes rainbow_animation {
    0% {
        background-position: 140% 0;
    }

    100% {
        background-position: 0 0;
    }
}

@keyframes rainbow_animation {
    0% {
        background-position: 140% 0;
    }

    100% {
        background-position: 0 0;
    }
}

/* Solid border */
hr.solid {
    border: 1px solid rgba(200, 200, 200, 0.6);
    border: 1px solid var(--button-background);
    border-radius: 5px;
    width: 100%;
}

/* This syntax means "any <button> elements inside something with ID "edge-list"" - the 2px is for above and
 below the button, the 5px is the left and right sides. This prevents the draw and clear buttons from being
 squished right next to each other. */
button {
    margin: 2px 5px;
    padding: 8px 16px;
    border-radius: 4px;
    border: none;
    font-family: 'Inter', sans-serif;
    background-color: rgba(200, 200, 200, 0.6);
    background-color: var(--button-background);
    transition: background-color 0.2s;
}

button:hover {
    background-color: rgba(235, 235, 235, 1);
    background-color: var(--button-background-hover);
}

button:active {
    background-color: rgba(210, 210, 210, 1);
    background-color: var(--button-background-active);
}

/* Makes parsing errors printed below the edge list red. */
#errors p {
    color: red;
    font-size: 12px;
    margin: 4px;
}

/* Centers the "Line Mapper!" text, which is in a <p> (paragraph) tag inside the <App /> component, and has an
ID of "app-title". Also makes the text bigger, bold, and adds some space around the title. */
#app-title {
    position: absolute;
    left: 0;
    /*right: 0;*/
    top: 0;
    width: 250px;
    z-index: 1000;
    text-align: center;
    font-size: 30px;
    font-weight: bold;
    padding-top: 10px;
    padding-bottom: 10px;
    margin: 35px;
    background: rgba(255, 255, 255, 0.7);
    background: var(--background);
    -webkit-backdrop-filter: blur(20px);
            backdrop-filter: blur(20px);
    border-radius: 20px;
}

